import { render, staticRenderFns } from "./catalog-index-swiper.vue?vue&type=template&id=fb9e76b6&"
import script from "./catalog-index-swiper.vue?vue&type=script&lang=js&"
export * from "./catalog-index-swiper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CatalogItemSmallDesktop2: require('/home/d/didisher2/autoexpert.moscow/www/components/catalog/catalog-item/catalog-item-small-desktop2.vue').default})
