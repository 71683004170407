
export default {

	props: {
		offers: Array,

		sliderId: {
			type: String,
			default: ''
		}
	},
	mounted() {

		let leftPadding = this.$refs.swiperСatalog.getBoundingClientRect().left
		let width = this.$refs.swiperСatalog.getBoundingClientRect().width
		let indexOfView1 = leftPadding * 2 / (width / 1) + 1

		let indexOfView2 = leftPadding * 2 / (width / 2) + 2
		const sd = new swiper.default('.swiper.swiper--catalog.swiper--' + this.sliderId, {
			modules: [swiper.Navigation, swiper.Autoplay],
			loop: false,
			// autoplayDisableOnInteraction: true,
			autoplay: false,
			initialSlide: 0,
			// centeredSlides: true,
			// activeIndex: 5,
			watchSlidesProgress: true,
			// allowTouchMove: false,
			// centeredSlidesBounds:true,
			// roundLengths: true,

			// centerInsufficientSlides:true,
			// slidesOffsetAfter: this.$refs.swiperСatalog.getBoundingClientRect().left,
			// cssMode:true,
			// edgeSwipeDetection:'prevent',
			// enabled: false,
			slidesPerView: 1.25,
			spaceBetween: 20,
			// slidesOffsetBefore: leftPadding,
			// slidesOffsetAfter: leftPadding,
			breakpoints: {
				// when window width is >= 320px
				0: {
					// slidesOffsetBefore: 16,
				},
				600: {
					slidesPerView: 2,
					spaceBetween: 24,


				}

			},
			navigation: {
				nextEl: '.catalog__list .swiper-button-next',
				prevEl: '.catalog__list .swiper-button-prev',
			},
		})

		sd.on('touchStart', (swiper, event) => {
			if (event.target.nodeName === 'IMG') swiper.allowTouchMove = false

		})

		sd.on('touchEnd', (swiper, event) => {
			swiper.allowTouchMove = true
		})




	},
	methods: {
		tabChange(car) {
			this.set = car.slug
			this.$emit('tabChange', car)
		}
	}
}
