
import seoTags from "@/mixins/seoTags";
// import LazyHydrate from 'vue-lazy-hydration';
export default {
	mixins: [seoTags],
	// 	components: {
	//     LazyHydrate,
	//   },
	mounted() {
		setTimeout(function () {
			window.scrollTo(0, -100);
		}, 1);
	},

}
